<template>
  <div class="account-login full-page">
    <div class="login flex">
      <div class="form-wrapper">
        <a-form name="login" :form="form" class="form">
          <div class="flex login-text">登录</div>

          <div class="input-wrapper bottom-line">
            <img src="./img/user.png" alt="" class="input-wrapper-img">
            <a-form-item label="" name="userName">
              <a-input :allow-clear="true" placeholder="用户名"
                       v-decorator="['userName', { rules: [{ required: true, message: '请输入用户名' }] }]"
                       class="login-input" @keypress.enter="onSubmit"></a-input>
            </a-form-item>
          </div>

          <div class="input-wrapper bottom-line">
            <img src="./img/lock.png" alt="" class="input-wrapper-img">
            <a-form-item label="" name="password">
              <a-input :allow-clear="true" placeholder="密码" type="password"
                       v-decorator="['password', { rules: [{ required: true, message: '请输入密码' }] }]"
                       class="login-input" @keypress.enter="onSubmit"></a-input>
            </a-form-item>
          </div>

          <div class="input-wrapper reset-password-wrapper">
            <a-button type="link" size="small" @click="resetPassword">忘记密码</a-button>
          </div>

          <a-form-item :wrapper-col="{offset: 9, span: 6}">
            <a-button type="primary" size="large" html-type="button" :loading="loading"
                      class="login-btn" @click="onSubmit">
              <span>登录</span>
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import { isProd } from '@/Config';
import { login } from '@/account/api';
import { redirect } from '@/plugins/redirect';

export default {
  name: 'Login',
  data() {
    return {
      loading: false,

      form: this.$form.createForm(this, { name: 'coordinated' }),

      userName: localStorage.getItem('username') || '',
      password: localStorage.getItem('password') || '',
    };
  },
  beforeRouteEnter(to, from, next) {
    const token = localStorage.getItem('token');
    const home = localStorage.getItem('home') || '';
    const path = isProd ? decodeURIComponent(home) : `/#/transition?token=${token}`;
    if (token) {
      sessionStorage.setItem('token', token || '');
      redirect(path || '');
    } else {
      next();
    }
  },
  methods: {
    onSubmit() {
      this.form.validateFields((err, values) => {
        if (err) {
          this.$message.info({ content: '请填写必填项' });
          return this.loading = false;
        }

        const params = {
          username: values.userName,
          password: values.password,
          callback: this.$route.query.callback_url || '',
        };

        this.login(params);
      });
    },
    async login(params) {
      if (this.loading) { return Promise.reject(new Error('Loading')); }
      this.loading = true;

      const data = await login(params).catch(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.loading = false;
        return this.$message.error(data?.message || '登录失败');
      }
      localStorage.setItem('token', data?.token || '');
      sessionStorage.setItem('token', data?.token || '');
      localStorage.setItem('username', params.username);
      localStorage.setItem('password', params.password);
      localStorage.setItem('home', data?.url || '');
      this.$message.success(data?.message || '登录成功');
      // this.$nextTick(() => {
      //   this.$redirect(data?.url || '');
      // });
      const path = isProd ? decodeURIComponent(data?.url) : `/#/transition?token=${data?.token}`;
      setTimeout(() => {
        this.$redirect(path || '');
        // this.loading = false;
      }, 1000);
      return Promise.resolve();
    },

    resetPassword() {
      this.$router.push('/account/reset');
    },
  },
};
</script>

<style scoped lang="scss">
.account-login {
  overflow: auto;
  position: relative;
  background: #F1F3FC;

  .login {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1200px;
    //height: 670px;
    max-width: 90%;
    min-height: 540px;
  }

  .form-wrapper {
    position: relative;
    width: 50%;
    min-width: 540px;
    max-width: 650px;
    min-height: 540px;
    border-radius: 5px;
    background: #F1F3FC;
    box-shadow: 0 4px 12px 8px #E8ECFF;
  }

  .form {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  .input-wrapper {
    position: relative;
    width: 60%;
    height: 72px;
    margin: 0 auto 5px;
    padding-left: 40px;
  }

  .reset-password-wrapper {
    margin-top: 20px;
    padding: 0;
  }

  .captcha-wrapper {
    padding-right: 100px;
  }

  .captcha-btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .bottom-line {
    border-bottom: 1px solid #DDDDDD;
  }

  .input-wrapper-img {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 23px;
  }

  .login-input {
    height: 50px;
    border: none !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    outline: none !important;
  }

  .login-input ::v-deep .ant-input {
    height: 100%;
    padding-top: 25px;
    border: none !important;
    background-color: transparent;
    box-shadow: none !important;
    outline: none !important;
  }

  ::v-deep .ant-form-explain {
    transform: translateY(-3px);
  }

  ::v-deep .ant-input-clear-icon {
    transform: translateY(12px);
  }

  .login-logo {
    width: 50%;
  }

  .login-text {
    margin-bottom: 25px;
    font-size: 36px;
    font-weight: 500;
    color: #5075FF;
  }

  .login-btn {
    width: 135px;
    margin-top: 55px;
  }


  .hint-msg {
    width: 60%;
    margin: 0 auto 5px;
    font-size: 12px;
    font-weight: 500;
    color: #83868E;
  }
}
</style>
