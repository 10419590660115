import request from '@/utils/Request';

// 获取短信验证码
export function getCaptcha(params) {
  return request({
    url: '/admin/pclogin/sendSms',
    method: 'post',
    params,
  });
}

// 修改密码
export function resetPassword(params) {
  return request({
    url: '/admin/pclogin/passwordForget',
    method: 'post',
    params,
  });
}

// pc登录
export function login(data) {
  return request({
    url: '/admin/pclogin/login',
    method: 'post',
    data,
  });
}

// pc注销登录
export function logout(params) {
  return request({
    url: '/admin/pclogin/Logout',
    method: 'post',
    params,
  });
}

